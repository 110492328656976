.panel {
  font-family: Roboto, sans-serif;
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.panel .info {
  text-align: center;
  padding-top: 1vh;
}

.panel h3 {
  color: #77bc3f;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0.5px;
  padding-top: 0.5vw;
  margin-bottom: 0;
}

.panel p {
  font-size: 24px;
  letter-spacing: 0.5px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 1000px) {

  .panel h3 {
    font-size: 32px;
  }
  .panel p {
    font-size: 18px;
  }
}