.navbar {
  display: flex;
  justify-content: space-between;
  background-color: #77bc3f;
  align-items: center;
  padding: 0px 2.6vw;
  height: 100px;
}

.navbar ul {
  display: flex;
  align-items: center;
}

.navbar li a {
  text-decoration: none;
  color: white;
  font-size: 14px;
  font-weight: 300;
  padding: 0 0.8vw;
}

.navbar li {
  list-style-type: none;
}

.logoNav {
  width: 300px;
  height: 100%;
  margin-top: 10px;
}

.phone img {
  width: 7.9vw;
}

@media (max-width: 800px) {
  .logoNav {
    width: 200px;
  }
  .SAlogo {
    display: none;
  }
  .navbar {
    flex-direction: column;
  }
}

@media (max-width: 450px) {
  .logoNav {
    width: 125px;
  }
}
