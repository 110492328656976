.clients {
    font-family: Roboto, sans-serif;
}

.clients h3 {
    font-size: 2.6vw;
    color: #77BC3F;

}

.clients p {
    margin-top: 0px;
    font-size: 1.7vw;
}

.clients .header {
    text-align: center;
}


@media (max-width: 550px) {
    .clients {
        display: none;
    }
}