.process {
  font-family: Roboto, sans-serif;
  padding-bottom: 5vh;
}

.blocks {
  display: flex;
  flex-wrap: wrap;
}

.process .block {
  width: 20%;
  height: auto;
}

.process .green {
  background-color: rgba(119, 188, 63, 0.15);
}

.process .grey {
  background-color: #ffffff;
}

.process h3 {
  color: #77bc3f;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0.5px;
  padding-top: 1vh;
}

@media (max-width: 1000px) {
  .process h3 {
    font-size: 32px;
  }
}

.process .block p:nth-child(1) {
  font-size: 5.9vw;
  color: #77bc3f;
  margin-bottom: 0px;
  margin-top: 6.79vh;
}

.process .block p:nth-child(2) {
  font-size: 1.42vw;
  width: 16vw;
}

.process .innerblock {
  padding-left: 1vw;
}
