.description {
  display: flex;
  font-family: Roboto, sans-serif;
}

.description .bio {
  width: 40vw;
  padding-bottom: 4.42vw;
}

.description .imageDiv {
  top: -85px;
  position: relative;
}

.description .bio h3 {
  color: #77bc3f;
  font-size: 2.5vw;
  letter-spacing: 1px;
  margin-bottom: 0px;
  font-weight: 500;
}

.description .bio p {
  font-size: 1.4vw;
  letter-spacing: 0.5px;
  width: 30vw;
  font-weight: 300;
}

.description img {
  width: 50vw;
  padding-left: 5vw;
}

@media (max-width: 1000px) {
  .showcase {
    height: 78vh;
  }
  .description .bio {
    width: 48%;
  }
  .description .bio h3 {
    font-size: 4vw;
  }
  .description .bio p {
    font-size: 2.4vw;
    width: 100%;
  }
  .description .imageDiv {
    top: -30px;
  }
}

@media (max-width: 550px) {
  .description .imageDiv {
  }
}
