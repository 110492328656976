.showcase {
  font-family: "Roboto", sans-serif;
  height: 65vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.showcase h1 {
  font-size: 4vw;
  font-weight: 500;
  font-style: regular;
  margin-top: 0px;
  margin-bottom: 0px;
}

.showcase p {
  font-size: 1.7vw;
  font-weight: 300;
}

.showcase .slogan {
  padding-top: 7vh;
  padding-left: 10%;
}

.showcase .quote {
  width: 12.18vw;
  height: 3.54vw;
  display: flex;
  border-radius: 52px;
  justify-content: space-evenly;
  align-items: center;
  background-color: #77bc3f;
  color: white;
  font-size: 1.25vw;
  font-weight: 300;
  cursor: pointer;
}

.quote:hover {
  transform: scale(1.1);
  -webkit-transition: transform 0.2s ease-in-out;
  box-shadow: 1px 1px rgba(230, 230, 230, 0.68);
}

.showcase .quote img {
  width: 1vw;
}

@media (max-width: 1400px) {
  .showcase {
    height: 55vh;
  }
}

@media (max-width: 1000px) {
  .showcase {
    height: 100%;
  }
  .showcase p {
    font-size: 1.5vw;
  }
  .showcase .quote {
    width: 12vw;
    height: 4vw;
  }
  .showcase .quote img {
    width: 1.5vw;
  }
  .showcase .slogan {
    padding-top: 3vh;
    padding-bottom: 3vh;
  }
}

@media (max-width: 750px) {
  .showcase h1 {
    font-size: 5vw;
  }
}

@media (max-width: 550px) {
  .showcase {
    background-repeat: no-repeat;
    background-size: cover;
    height: 40vh;
  }
  .showcase p {
    font-size: 2.2vw;
  }
  .showcase .quote {
    width: 22vw;
    height: 6vw;
    font-size: 2.6vw;
  }
}

.flexDiv {
  display: flex;
}

.download {
  margin-left: 50px;
}