.services {
  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5vw;
}

h3 {
  color: #77bc3f;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.services .servicecircles {
  text-align: center;
  width: 16.3vw;
}

.servicecircles:hover {
  transform: scale(1.2);
  -webkit-transition: transform 0.4s ease-in-out;
}

.servicepanel {
  display: flex;
  flex-direction: row;
}

@media (max-width: 1000px) {
  h3 {
    font-size: 32px;
  }
  .services {
    height: 400px;
  }
}

@media (max-width: 700px) {
  .services .servicecircles {
    width: 40%;
    font-size: 10px;
  }

  .servicecircles img {
    width: 40%;
  }
  .services {
    height: 300px;
  }
}
