.quadrant .section {
  display: flex;
  width: 100%;
}

.quadrant h3 {
  font-size: 36px;
  padding-bottom: 0vw;
  color: #77bc3f;
  margin-top: 15px;
  margin-bottom: 15px;
}

.quadrant p {
  font-size: 24px;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
}

.quadrant .grey {
  background-color: #f2f2f2;
  width: 50%;
}

.quadrant .infosec {
  padding: 20px;
}

.infosec h3 {
  text-align: center;
}

.imageWrapper {
  width: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media only screen and (max-width: 1000px) {
  .quadrant .section {
    flex-direction: column;
  }

  .quadrant .section2 {
    flex-direction: column-reverse;
  }

  .quadrant .grey {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .quadrant img {
    width: 100%;
  }

  .quadrant .infosec {
    padding-top: 0px;
    padding-bottom: 15px;
    width: 100%;
    text-align: center;
  }

  .quadrant h3 {
    font-size: 32px;
  }

  .quadrant p {
    font-size: 18px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .imageWrapper {
    height: 400px;
    width: 100%;
  }

  .infosec img {
    margin-top: 10px;
  }
}
