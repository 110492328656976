$slide-count: 6;
$slide-width: 30rem;

html {
    box-sizing: border-box;
    font-size: 1vw;
}

body {
    font-size: 1.5rem;
    // margin: 0;
    font-family: Arial, Helvetica, sans-serif;
}

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    box-sizing: inherit;
}

ul {
    list-style-type: none;
    padding: 0;
}

a {
    text-decoration: none;
    color: inherit;
}

.carousel__wrap {
    // margin-top: 10%;
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    position: relative;
    width: 100%;
    // left: 50%;
    // transform: translateX(-50%);
}

.carousel__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 600px;
    position: relative;
    width: calc(#{$slide-width * 3});
}

.carousel__container {
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.carousel__slide-list {
    height: 100%;
    left: 53%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    transform: translateX(-50%);
    width: calc(#{($slide-count + 0.5) * $slide-width * 2});
    padding-bottom: 20px;
}

.carousel__slide-item {
    display: inline-block;
    // height: $slide-width;
    margin: 0;
    padding: 1rem;
    position: absolute;
    transition: all 0.3s;
    width: $slide-width;
}

// .carousel__slide-item-img-link {
//     cursor: zoom-in;
//     display: flex;
//     height: 100%;
//     overflow: hidden;
//     position: relative;
//     width: 100%;

// img {
//     height: 100%;
//     object-fit: cover;
//     transition: all 0.5s ease;
//     width: 100%;
// }

//     &::after {
//         align-items: center;
//         background: rgba(black, 0.5);
//         color: white;
//         content: 'read more';
//         display: flex;
//         height: 100%;
//         justify-content: center;
//         opacity: 0;
//         position: absolute;
//         transition: all 0.5s ease;
//         width: 100%;
//     }

//     &:hover {
//         &::after {
//             opacity: 1;
//         }

//         // img {
//         //     transform: scale(1.3);
//         // }
//     }
// }

.carousel-slide-item__body {
    bottom: -2.5rem;
    height: 10%;
    position: absolute;

    h4 {
        margin: 0.7rem 0 0;
        text-transform: uppercase;
        color: #77BC3F;
    }

    p {
        font-size: 1.2rem;
        line-height: 1.3;
        margin: 0.7rem 0 0;
    }
}

// .carousel__btn {
//     // align-items: center;
//     background: 0;
//     border: 0;
//     cursor: pointer;
//     display: flex;
//     justify-content: center;
//     // position: absolute;
//     // top: 50%;
//     // transform: translateY(-50%);

//     // &--prev {
//     //     left: -10rem;
//     // }

//     // &--next {
//     //     right: -10rem;
//     // }
// }

// .carousel__btn-arrow {
//     // z-index: 10;
//     // border: solid black;
//     // border-width: 0 0.4rem 0.4rem 0;
//     // background-color: #77BC3F;
//     // border-radius: 50%;
//     // height: 6rem;
//     // padding: 3px;
//     // width: 6rem;
//     // z-index: 10;

//     // &--left {
//     //     transform: rotate(135deg);
//     // }

//     // &--right {
//     //     transform: rotate(-45deg);
//     // }
// }

// // .carousel__dots {
// //     display: inline-block;
// //     left: 50%;
// //     margin-top: 2rem;
// //     position: absolute;
// //     transform: translateX(-50%);

// //     .dot {
// //         background: #ccc;
// //         border: 0;
// //         border-radius: 50%;
// //         cursor: pointer;
// //         height: 2rem;
// //         margin: 0 0.3rem;
// //         outline: none;
// //         transform: scale(0.5);
// //         width: 2rem;

// //         &.active {
// //             background: black;
// //         }
// //     }
// // }

.buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 30px;
}

.buttons img {
    width: 3.5vw;
    cursor: pointer;
}



button {
    border: none;
    background-color: transparent;
    margin-bottom: 20px;
    z-index: 10000;
}

button:hover {
    transform: scale(1.1);
    -webkit-transition: transform 0.4s ease-in-out;
}

.carousel-slide-item__body p {
    text-align: center;
    width: 22vw;
    font-size: 16px;
}

.carousel-slide-item__body div {
    text-align: center;
    width: 22vw;
    font-size: 16px;
    font-style: italic;
}

.carousel-slide-item__body h4 {
    text-align: center;
    margin-top: 20px;
}

.carousel-slide-item__body img {
    width: 60px;
    margin-right: 10px 
}


@media (max-width: 1400px) {
    .carousel__inner {
        height: 600px;
    }
}

@media (max-width: 1250px) {
    .carousel-slide-item__body {
        bottom: -1rem;
    }
    
}

@media (max-width: 1000px) {
    .carousel-slide-item__body {
        bottom: 0rem;
    }
    .carousel__inner {
        height: 650px;
    }
}

@media (max-width: 800px) {
    button {
        margin-top: -8vw;
    }

    .carousel__inner {
        height: 700px;
    }

    .carousel-slide-item__body img {
        width: 30px;
        margin-right: 10px 
    }

    .carousel-slide-item__body p {
        font-size: 14px;
    }

    .carousel-slide-item__body div {
        font-size: 12px;
    }
}
