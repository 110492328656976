.footer {
  display: flex;
  justify-content: space-between;
  background-color: #77bc3f;
  align-items: center;
  padding: 0px 2.6vw;
  height: 100px;
  overflow-y: hidden;
}

.footer ul {
  display: flex;
  align-items: center;
}

.footer li a {
  text-decoration: none;
  color: white;
  font-size: 14px;
  font-weight: 300;
  padding: 0 0.8vw;
}

.footer li {
  list-style-type: none;
}

.logo {
  width: 300px;
  height: 100%;
  margin-top: 10px;
}

.phone img {
  width: 7.9vw;
}

@media (max-width: 800px) {
  .logo {
    width: 200px;
  }
  .SAlogo {
    display: none;
  }
  .footer {
    flex-direction: column;
  }
}

@media (max-width: 450px) {
  .logo {
    width: 125px;
  }
}
